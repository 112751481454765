import { Button, Modal, TextArea } from "@appkit4/react-components";

interface NotificationModalProps {
    visible: boolean;
    closeModal: Function;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ visible, closeModal }) => {
    return (
        <Modal
            visible={visible}
            header={
                <div className="flex-col">
                    <h1 className="font-sans font-medium text-xl mb-4">Asset ######</h1>
                    <div className="font-small text-sm text-[#696969]">Find here the detailed argument for the rejection of your request.</div>
                </div>}
            onCancel={closeModal}
            modalStyle={{ width: '36rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <TextArea
                title={"From migration manager"}
                maxLength={420}
                value='Ownership of the asset has been denied because the current owner has more relevant expertise and responsibility for its management, ensuring continuity and accuracy in the migration process.'
                disabled>
            </TextArea>
            <div className="flex justify-end gap-2 mt-4">
                <Button kind='secondary' onClick={closeModal}>Go back</Button>
                <Button kind='primary' icon="icon-arrow-right-outline" onClick={() => console.log("go to asset called")}>Go to asset</Button>
            </div>
        </Modal>);
}

export default NotificationModal;
