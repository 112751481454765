import { CalendarPicker } from '@appkit4/react-components';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

type ControlledDatePickerProps = {
  name: string;
  label?: string;
  error?: FieldError,
  required?: boolean,
  onChange?: Function,
  maxDate?: Date,
  minDate?: Date,
  disabled?: boolean
};

const ControlledDatePicker: React.FC<ControlledDatePickerProps> = ({ name, label = "", error, required = false, onChange = {}, maxDate, minDate, disabled = false }) => {
  const { control } = useFormContext();
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{ required: `Missing ${label.toLowerCase()}` }}
        render={({ field }) => <CalendarPicker
          className={classNames({
            'error': error
          })}
          fieldWidth="full"
          placeholder="mm/dd/yyyy"
          locale="en"
          fieldTitle={label}
          required={required}
          onValueChange={onChange}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          id={name} {...field} />}
      />
    </div>
  );
};

export default ControlledDatePicker;
