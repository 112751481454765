import React, { useEffect, useState } from 'react';
import { NewProjectForm } from '../../../components/project';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { selectProjectError, selectProjectLoading } from '../../../store/project/projectSelectors';
import { CreateProjectRequest } from '../../../api';
import { createNewProject, getUsers } from '../../../store/project/projectThunks';
import { Loading } from '@appkit4/react-components';

const CreateNewProjectContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(selectProjectLoading);
  const error = useSelector(selectProjectError);
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSubmitCalled, setIsSubmitCalled] = useState(false);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitCalled && !error && !loading){
      setIsProjectCreated(true);
    }
  }, [error, loading, isSubmitCalled])

  const handleSubmit = async (projectData: CreateProjectRequest) => {
    await dispatch(createNewProject(projectData));
    setIsSubmitCalled(true);
  };
  return (
    <div className="container mx-auto py-2">
      {loading ?
        <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        :
        <>
          <NewProjectForm onSubmit={handleSubmit} loading={loading} isProjectCreated={isProjectCreated}
            activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </>
      }

    </div>
  );
};

export default CreateNewProjectContainer;
