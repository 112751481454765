import { Panel } from "@appkit4/react-components";
import ControlledMultiSelect from "../../../core/components/ControlledMultiselect";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUsersListByRole } from "../../../store/project/projectSelectors";
import { KeycloakService } from "../../../services/KeycloakService";
import { UserDTO } from "../../../api";

type ProjectFormErrors = {
    businessUserEmails: string;
};

const AddUserBody: React.FC<{addBusinessUserEmails: Function}> = ({addBusinessUserEmails}) => {
    const { formState: { errors } } = useFormContext<ProjectFormErrors>();
    const listOfUsers = useSelector(selectUsersListByRole);
    const businessUsers:{ value: string, label: string }[]  = listOfUsers[0]?.users.filter((user: any) => user.email !== KeycloakService.getEmail()).map((adminUser:UserDTO) => {return{value: adminUser.email, label: adminUser.email}});

    return (
        <>
            <Panel bordered={false} title="Invite users into the Business User role" >
                <p>The Business User role is responsible for managing and uploading data assets within their organization.</p>
                <ControlledMultiSelect name="businessUserEmails" required={false} label="Business User" error={errors.businessUserEmails} data={businessUsers} addEmails={addBusinessUserEmails}/>
                {errors.businessUserEmails && <p className="text-red-500 mt-2">{errors.businessUserEmails.message}</p>}
            </Panel>
        </>
    )
}

export default AddUserBody;