import { Combobox } from '@appkit4/react-components'
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

type ControlledMultiSelectProps = {
    name: string;
    data: Array<Object>;
    label?: string;
    error?: FieldError,
    required?: boolean,
    addEmails?: Function
};

const ControlledMultiSelect: React.FC<ControlledMultiSelectProps> = ({ name, label = "", error, required = false, data, addEmails=() => {} }) => {
    const { control, setValue } = useFormContext();
    return (
        <div className="mt-4">
            <Controller
                name={name}
                control={control}
                rules={{ required: required? `Missing ${label.toLowerCase()}`: "" }}
                render={() =>
                    <Combobox
                        onSelect={e => {
                            if(addEmails)
                                addEmails(e);
                            setValue(name, e);
                        }}
                        className={classNames({
                            'error': error
                        })}
                        data={data}
                        showSelectAll={false}
                        placeholder={"Email"}
                        multiple
                        showMultipleTags
                        showAllSelectedTag={false}
                        dropdownStyle={{"maxHeight": "12rem"}}
                        dropdownRenderMode="portal"
                    />
                }
            />
        </div>
    );
};

export default ControlledMultiSelect;
