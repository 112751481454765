import React from 'react';
import { Badge, Breadcrumb, BreadcrumbItem, Button, Panel } from '@appkit4/react-components';
import backGroundImage from "../../assets/images/genericEmptyState.svg";
import { ProjectDTO } from '../../api';
import { selectAllProjectsList } from '../../store/project/projectSelectors';
import { useSelector } from 'react-redux';

const LandingBusinessUser: React.FC<{ goToViewProject: (project: ProjectDTO) => void }> = ({ goToViewProject }) => {
    const listOfProjects: any[] = useSelector(selectAllProjectsList);
    const nonArchiveProjectData = listOfProjects?.filter((project) => project?.projectStatus !== "archived");
    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat',
        height: '22rem'
    };
    const viewProject = (project: ProjectDTO) => {
        goToViewProject(project);
    }
    return (
        <div>
            <Breadcrumb className="px-8 py-4">
                <BreadcrumbItem className="!text-blue-600">
                    Your projects
                </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="font-sans font-medium text-xl pl-8">Your projects</h1>
            {!(listOfProjects?.length > 0) ?
                <div className="ml-8 mr-8 mb-2 bg-white">
                    <div style={backgroundImageStyle} className="bg-center"></div>
                    <div className="flex flex-col text-center items-center pb-8">
                        <h1 className="font-medium text-xl p-2"> You don't have any projects assigned </h1>
                        <div className="text-[#696969]"> Please contact administrator of the migration project to clarify this topic.</div>
                    </div>
                </div>
                :
                <div className="ml-8 mr-8 mb-2">
                    <h3 className="font-medium text-sm py-2 mb-2">Choose a project from the list below to view an overview of all assets and use cases. Manage or edit them as needed.</h3>
                    <div className="flex flex-wrap">
                        {nonArchiveProjectData?.map((project, index) =>
                            <div className="w-1/3 mt-4 px-2" key={index}>
                                <Panel title={project.name} className="min-h-56">
                                    <Badge className="mb-6" value={project.projectStatus} type={project.projectStatus === "ongoing" ? "success" : "warning"} />
                                    <div className="flex justify-end mt-14">
                                        <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => viewProject(project)}>View Project</Button>
                                    </div>
                                </Panel>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default LandingBusinessUser;
