
import React from 'react';
import { LandingAdmin } from '../../components/Landing';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProjectError, selectProjectLoading } from '../../store/project/projectSelectors';
import { ProjectDTO } from '../../api';

const LandingContainerAdmin: React.FC<{goToViewProject: (project:ProjectDTO) => void}> = ({goToViewProject}) => {
    const navigate = useNavigate();
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    const handleCreateNew = () => {
        navigate("/createProject");
    }
    return (
        <>
            <LandingAdmin onSubmit={handleCreateNew} loading={loading} goToViewProject={goToViewProject}/>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </>
    )
}

export default LandingContainerAdmin;