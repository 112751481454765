import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetDTO, GetAllAssetsResponse, ProjectConfigDTO, ProjectDTO, UserByRole } from '../../api/types.gen';

interface ProjectState {
  loading: boolean;
  error: string | null;
  userEmailsTableData: Array<any>;
  projectInitialDetails: object;
  usersByRoleList: UserByRole[];
  allProjectsList: ProjectDTO[];
  userEmailsForProject: Array<any>;
  selectedProjectData: ProjectDTO;
  selectedProjectConfigData: ProjectConfigDTO;
  assetDataForProject: GetAllAssetsResponse;
  selectedAsset: AssetDTO;
  isEditModeAsset: boolean;
}

const initialState: ProjectState = {
  loading: false,
  error: null,
  allProjectsList: [],
  userEmailsTableData: [],
  usersByRoleList: [],
  projectInitialDetails: {
    name: "",
    type: "",
    startDate: null,
    endDate: null,
    description: "",
    adminEmails: [],
    managerEmails: []
  },
  selectedProjectData: {
    name: "",
    id: "",
    projectStatus: "ongoing",
    type: "",
    startDate: "",
    endDate: "",
    description: "",
    adminList: [],
    managerList: []
  },
  userEmailsForProject: [],
  selectedProjectConfigData: {
    projectId: "",
    config: {
    }
  },
  assetDataForProject: {
  },
  selectedAsset: {
    id: '',
    assetName: '',
    assetType: 'aiModel',
    projectId: '',
  },
  isEditModeAsset: false
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setUserEmailsTableData: (state, action: PayloadAction<Array<Object>>) => {
      state.userEmailsTableData = action.payload
    },
    setProjectInitialDetails: (state, action: PayloadAction<object>) => {
      state.projectInitialDetails = action.payload
    },
    setUsersByRole: (state, action: PayloadAction<UserByRole[]>) => {
      state.usersByRoleList = action.payload
    },
    setAllProjectsList: (state, action: PayloadAction<ProjectDTO[]>) => {
      state.allProjectsList = action.payload
    },
    setUserEmailsForProject: (state, action: PayloadAction<Array<Object>>) => {
      state.userEmailsForProject = action.payload
    },
    setSelectedProjectData: (state, action: PayloadAction<ProjectDTO>) => {
      state.selectedProjectData = action.payload
    },
    setProjectConfigData: (state, action: PayloadAction<ProjectConfigDTO>) => {
      state.selectedProjectConfigData = action.payload
    },
    setAssetDataForProject: (state, action: PayloadAction<GetAllAssetsResponse>) => {
      state.assetDataForProject = action.payload
    },
    setSelectedAsset: (state, action: PayloadAction<AssetDTO>) => {
      state.selectedAsset = action.payload
    },
    setIsEditModeAsset: (state, action: PayloadAction<boolean>) => {
      state.isEditModeAsset = action.payload
    }
  },
});

export const { setLoading, setError, setUserEmailsTableData, setProjectInitialDetails, setUsersByRole, setAllProjectsList,
  setUserEmailsForProject, setSelectedProjectData, setProjectConfigData, setAssetDataForProject, setSelectedAsset, setIsEditModeAsset } = projectSlice.actions;
export default projectSlice.reducer;