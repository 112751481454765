import { Breadcrumb, BreadcrumbItem, Button } from "@appkit4/react-components";
import { selectSelectedProjectData } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import DataAssetsDetailsBusinessUser from "../../../components/project/dataAssets/DataAssetsDetailsBusinessUser";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const DataAssetsContainerBusinessUser = () => {
    const selectedProject = useSelector(selectSelectedProjectData);
    const navigate = useNavigate();
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        navigate("/dashboard")
                    }}>Your projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {selectedProject?.name}
                </BreadcrumbItem>
            </Breadcrumb>
            <DataAssetsDetailsBusinessUser selectedProject={selectedProject} />
        </div>
    )
}

export default DataAssetsContainerBusinessUser;