import { Breadcrumb, BreadcrumbItem, Button } from "@appkit4/react-components";
import { selectSelectedAsset, selectSelectedProjectData } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import DataAssetInfoBusinessUser from "../../../components/project/dataAssets/DataAssetInfoBusinessUser";
import { useNavigate } from "react-router-dom";

const DataAssetsDetailsContainerBusinessUser = () => {
    const selectedProject = useSelector(selectSelectedProjectData);
    const selectedAsset = useSelector(selectSelectedAsset);
    const navigate = useNavigate();
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        navigate("/dashboard");
                    }}>Your projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        navigate(`/project/${selectedProject?.id}`);
                    }}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {selectedAsset?.assetName}
                </BreadcrumbItem>
            </Breadcrumb>
            <DataAssetInfoBusinessUser />
        </div>
    )
}

export default DataAssetsDetailsContainerBusinessUser;