import React from 'react';
import backGroundImage from "../../assets/images/background.svg";
import { LoginContainer } from '../../containers/login';
import FooterApp from '../../core/components/Footer';
import HeaderApp from '../../core/components/Header';

const LoginPage: React.FC = () => {

    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat'
    };

    return (
        <div className="flex">
            <div className="flex flex-col flex-1">
                <HeaderApp />
                <main className="flex flex-grow container items-center justify-center">
                    <LoginContainer />
                </main>
                <FooterApp />
            </div>
            <div style={backgroundImageStyle} className="flex-1 h-screen w-full bg-contain bg-right"></div>
        </div>
    );
};

export default LoginPage;