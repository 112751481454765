import { Breadcrumb, BreadcrumbItem, Button, Loading } from '@appkit4/react-components';
import { ProjectDTO } from '../../../api';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { KeycloakService } from '../../../services/KeycloakService';
import { selectProjectError, selectProjectLoading, selectSelectedProjectData, selectUserEmailsForProject } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { setUserEmailsForProject } from '../../../store/project/projectSlice';
import { UsersWithEmailTable } from '../../../core/components';
import EditUserModal from '../../../components/project/projectDetails/EditUsersModal';
import AddUserModal from '../../../components/project/projectDetails/AddUserModal';
import { getOrgName, removeDuplicates, uniqueArray } from '../../../core/util/utility';
import AddUserFormProvider from '../../../components/project/projectDetails/AddUserFormProvider';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DeleteUserConfModal from '../../../components/project/projectDetails/DeleteUserConfModal';
import { addProjectMember, editProjectMemberRole, getProjectsList, removeProjectMember } from '../../../store/project/projectThunks';
import { useNavigate } from 'react-router-dom';

const TeamMembersDetails: React.FC<({ selectedProject: ProjectDTO })> = ({ selectedProject }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [visibleAddUser, setVisibleAddUser] = useState(false);
    const [visibleDltModal, setVisibleDltModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({ email: "", role: "" });
    const [userDetails, setUserDetails] = useState({ email: "", role: "" });
    const email = KeycloakService.getEmail();
    const tableData = JSON.parse(JSON.stringify(useSelector(selectUserEmailsForProject)));
    const { getValues } = useFormContext();
    const getEmailsList = useCallback(() => {
        const emailsArray = [];
        const createEmailsArray: Array<any> = [];
        selectedProject.managerList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "migration-manager" };
            createEmailsArray.push(arrayElement);
        });
        selectedProject.adminList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "admin" };
            createEmailsArray.push(arrayElement);
        });
        selectedProject.businessUserList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "business-user" };
            createEmailsArray.push(arrayElement);
        });
        emailsArray.push(...createEmailsArray);
        const updatedArrayList = removeDuplicates(emailsArray, 'email');
        return updatedArrayList;
    }, [selectedProject]);
    useEffect(() => {
        dispatch(setUserEmailsForProject(getEmailsList()))
    }, [dispatch, getEmailsList]);
    const closeModal = () => {
        setVisible(false);
    }
    const closeAddUserModal = () => {
        setVisibleAddUser(false);
    }
    const addUsers = () => {
        const adminEmailsList: string[] = getValues().adminEmails || [];
        const oldAdminData = new Set(selectedProject?.adminList);
        const filteredAdminEmails = adminEmailsList?.filter(item => !oldAdminData.has(item));
        const adminEmailsUpdated = uniqueArray(filteredAdminEmails);
        const managerEmailsList: string[] = getValues().managerEmails || [];
        const oldManagerData = new Set(selectedProject?.managerList);
        const filteredManagerEmails = managerEmailsList?.filter(item => !oldManagerData.has(item));
        const managerEmailsUpdated = uniqueArray(filteredManagerEmails);
        dispatch(addProjectMember({ id: selectedProject?.id, adminList: adminEmailsUpdated, managerList: managerEmailsUpdated, businessUserList: [] }))
        setVisibleAddUser(false);
    }
    const deleteRow = (row: any) => {
        setVisibleDltModal(true);
        setSelectedRow(row)
    }

    const closeDltModal = () => {
        setVisibleDltModal(false);
    }

    const deleteUser = () => {
        dispatch(removeProjectMember({ projectId: selectedProject?.id, userEmail: selectedRow?.email }));
        setVisibleDltModal(false);
    }
    const editRow = (row: any) => {
        setUserDetails({ email: row?.email, role: row?.role });
        setVisible(true);
    }
    const editUser = (userRole: string) => {
        dispatch(editProjectMemberRole({ id: selectedProject?.id, userEmail: userDetails?.email, userRole: userRole }))
        setVisible(false);
    }
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    if (loading) {
        return (
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }
    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList());
                        navigate("/dashboard");
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => navigate(`/project/${selectedProject?.id}`)}>{selectedProject?.name}</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    Team Members
                </BreadcrumbItem>
            </Breadcrumb>
            <div className="flex justify-between">
                <div>
                    <h1 className="font-sans font-medium text-xl mt-4">Team</h1>
                    <p>{(selectedProject?.adminList?.length || 0) + (selectedProject?.managerList?.length || 0) + (selectedProject?.businessUserList?.length || 0)} users</p>
                </div>
                <div className="flex justify-end mt-8">
                    <Button kind='primary' icon="icon-plus-outline" onClick={() => setVisibleAddUser(true)}>Add new</Button>
                </div>
            </div>
            {tableData?.length > 0 &&
                <div className="my-4">
                    <UsersWithEmailTable tableData={tableData} email={email} deleteRow={deleteRow} isCompanyRequired={false} editable={true} editRow={editRow} />
                    <EditUserModal visible={visible} closeModal={closeModal} editUser={editUser} userDetails={userDetails} />
                </div>
            }
            <AddUserModal visible={visibleAddUser} closeModal={closeAddUserModal} addUsers={addUsers} />
            <DeleteUserConfModal visible={visibleDltModal} closeModal={closeDltModal} deleteUser={deleteUser} />
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}

const TeamMembersContainer = () => {
    const selectedProject = useSelector(selectSelectedProjectData);
    const addUsersEmails = (data: {
        emailId: string;
        role: string;
    }) => {
        console.log("data", data);
    }
    return (
        <AddUserFormProvider onSubmit={addUsersEmails}>
            <TeamMembersDetails selectedProject={selectedProject} />
        </AddUserFormProvider>
    );
}


export default TeamMembersContainer;