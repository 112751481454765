import { Header } from '@appkit4/react-components/header';
import { Avatar } from '@appkit4/react-components/avatar';
import { DropdownButton } from '@appkit4/react-components/dropdown';
import { KeycloakService } from '../../services/KeycloakService';
import { useNavigate } from 'react-router-dom';

const LoggedInHeader = () => {
    const initials = KeycloakService.getInitials();
    const navigate = useNavigate();
    const onClickLogo = () => {
        navigate("/dashboard");
    }
    const menuItemClicked = (e: any) => {
        if (e === "logout")
            KeycloakService.logout();
    }
    return (
        <Header
            className="bg-[#F3F3F3]"
            type="transparent"
            compact={false}
            onClickLogo={onClickLogo}
            titleTemplate={() => <span className="ml-2 cursor-pointer" onClick={onClickLogo}>Data Migration Workbench</span>}
            userTemplate={() => (
                <DropdownButton
                    customTriggerNode={true}
                    customTriggerClassName="custom-node"
                    onSelect={menuItemClicked}
                    data={[
                        { value: 'logout', label: 'Logout', iconName: 'lockclosed-locked-outline' }
                    ]}
                    prefixTemplate={(item) => <span className={`Appkit4-icon icon-${item.iconName}`}></span>}
                >
                    <Avatar role="button" disabled={false} label={initials} />
                </DropdownButton>)}>
        </Header>
    )
}

export default LoggedInHeader;