import { Button, Modal } from "@appkit4/react-components";
import RequestFormProvider from "./RequestFormProvider";
import { ControlledTextArea } from "../../../core/components";
import { useFormContext } from "react-hook-form";

interface DeleteUserConfModalProps {
    visible: boolean;
    closeModal: Function;
    removeAsset: any;
}

type RemoveOwnershipFormErrors = {
    removeOwnershipComment: string;
};

const RemovalConfirmationModal: React.FC<DeleteUserConfModalProps> = ({ visible, closeModal, removeAsset }) => {
    return (
        <Modal
            visible={visible}
            title={"Remove asset"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={removeAsset}>
                <RemoveOwnershipForm />
            </RequestFormProvider>
        </Modal>);
}

const RemoveOwnershipForm = () => {
    const { formState: { errors } } = useFormContext<RemoveOwnershipFormErrors>();
    return (
        <div>
            <div className="font-small text-sm text-[#696969]">If you have a reason for this asset to be removed, please write it in the comments and inform the manager.</div>
            <div className="my-4">
                <ControlledTextArea name="removeOwnership" required={true} label="Comment" error={errors.removeOwnershipComment} maxLength={420} />
                {errors.removeOwnershipComment && <p className="!text-red-500 mt-2">{errors.removeOwnershipComment.message}</p>}
            </div>
            <div className="my-4 flex justify-end">
                <Button kind="primary" icon="icon-delete-outline" type="submit">Request removal</Button>
            </div>
        </div>
    )
}

export default RemovalConfirmationModal;