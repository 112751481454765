import React, { useState } from 'react';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import LineageModelDetails from "./LineageModelDetails";
import CSVDetails from "../CSVDetails";
import { Button } from '@appkit4/react-components';

const LineageConfig: React.FC<{ setActiveIndexForm: Function }> = ({ setActiveIndexForm }) => {
    const [activeIndex, setactiveIndex] = useState(0);
    const onTabChange = (i: number, value: string) => {
        setactiveIndex(i);
    }
    const nextClicked = () => {
        setActiveIndexForm(2);
    }
    const cancelProject = () => {
        setActiveIndexForm(0);
    }
    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            <h1 className="font-sans font-bold font-medium text-2xl py-4">Lineage configuration</h1>
            <div className="p-8 bg-white">
                <Tabs activeIndex={activeIndex} onTabChange={onTabChange}>
                    <Tab label='Lineage model' value="lineageModel">
                        <LineageModelDetails />
                    </Tab>
                    <Tab label='CSV File' value="csvFile">
                        <CSVDetails />
                    </Tab>
                </Tabs>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' onClick={cancelProject}>Back</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" onClick={nextClicked}>Next</Button>
                </div>
            </div>

        </div>
    )
}

export default LineageConfig;