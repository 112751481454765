import { AssetStatusMapping, assetTypeOptions, dataAssetsWithPreviewMapping, itemsPerPageOptions, surveyStatusIndicators } from "../../../services/constants";
import { useEffect, useState } from "react";
import AddDataAssetModal from "../../../components/project/dataAssets/AddDataAssetModal";
import { Accordion, AccordionItem, Badge, Button, Checkbox, Column, Notice, Pagination, Search, Select, Table, Tag } from "@appkit4/react-components";
import ManageDataAssetModal from "./ManageAssetModal";
import NotificationModal from "./NotificationModal";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { addNewAssetInProject, getAssetTableData } from "../../../store/project/projectThunks";
import { ProjectDTO } from "../../../api";
import { useSelector } from "react-redux";
import { selectGetAllAssetForProject, selectSelectedAsset } from "../../../store/project/projectSelectors";
import { setSelectedAsset } from "../../../store/project/projectSlice";

const DataAssetsDetailsBusinessUser: React.FC<{ selectedProject: ProjectDTO }> = ({ selectedProject }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isVisibleInfo, setVisibleInfo] = useState(false);
    const [isAddAssetModalVisible, setIsAddAssetModalVisible] = useState(false);
    const [current, setCurrent] = useState(1);
    const [selectedValue, setSelectedValue] = useState("10");
    const [isOwnedByMeChecked, setIsOwnedByMeChecked] = useState(false);
    const [isUnassignedChecked, setIsUnassignedChecked] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isManageAssetVisible, setIsManageAssetVisible] = useState(false);
    const [activeKeys, setActiveKeys] = useState<string[]>([]);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const selectedAsset = useSelector(selectSelectedAsset);
    const navigate = useNavigate();
    const listOfAssets = useSelector(selectGetAllAssetForProject);
    const selectedFilters = [];
    const closeNotification = () => {
        setIsNotificationVisible(false);
    }
    if (isOwnedByMeChecked) selectedFilters.push("OwnedByMe");
    if (isUnassignedChecked) selectedFilters.push("Unassigned");
    const onPageChange = (page: number) => {
        setCurrent(page);
    }
    const closeManageAssetModal = () => {
        setIsManageAssetVisible(false);
    }
    const handleSearch = (e: string) => {
        setSearchText(e);
    }
    const closeAddAssetModal = () => {
        setIsAddAssetModalVisible(false);
    }
    const ownCheckedChange = () => {
        setIsOwnedByMeChecked(!isOwnedByMeChecked);
    }
    const unassignedChange = () => {
        setIsUnassignedChecked(!isUnassignedChecked);
    }
    const removeFilter = (e: string) => {
        if (e === "OwnedByMe") setIsOwnedByMeChecked(false);
        if (e === "Unassigned") setIsUnassignedChecked(false);
    }
    const clearFilter = () => {
        setSearchText("")
        setIsOwnedByMeChecked(false);
        setIsUnassignedChecked(false);
    }
    const onClickAccordion = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    const addNewAsset = async (data: any) => {
        const assetDataReq = {
            assetName: data?.assetName,
            assetType: data?.assetType,
            projectId: selectedProject?.id,
            assetDescription: data?.assetDescription
        }
        const reqPayload = {
            searchString: searchText,
            isOwnedByMeChecked: isOwnedByMeChecked,
            isUnassignedChecked: isUnassignedChecked,
            pageNumber: current,
            pageSize: Number(selectedValue),
            projectId: selectedProject.id
        }
        await dispatch(addNewAssetInProject(assetDataReq));
        dispatch(getAssetTableData(reqPayload))
        closeAddAssetModal();
    }
    useEffect(() => {
        const reqPayload = {
            searchString: searchText,
            isOwnedByMeChecked: isOwnedByMeChecked,
            isUnassignedChecked: isUnassignedChecked,
            pageNumber: current,
            pageSize: Number(selectedValue),
            projectId: selectedProject.id
        }
        if (selectedProject?.id) dispatch(getAssetTableData(reqPayload));
    }, [dispatch, searchText, isOwnedByMeChecked, isUnassignedChecked, current, selectedValue, selectedProject]);
    return (
        <>
            <div className="flex justify-between">
                <div>
                    <h1 className="font-sans font-medium text-xl mt-4">{selectedProject?.name}</h1>
                    <h3>{selectedProject?.description}</h3>
                </div>
                <Button className="m-4" type="primary" icon="icon-plus-outline" onClick={() => setIsAddAssetModalVisible(true)}>Add asset</Button>
                <AddDataAssetModal visible={isAddAssetModalVisible} closeModal={closeAddAssetModal} addAsset={addNewAsset} />
            </div>
            <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys} className="my-4">
                <AccordionItem title={"Notification"} itemKey="1">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                            <span>Asset #####: Your request for the removal/ownership of the asset has been rejected by the Migration Manager.</span>
                            <Button kind="text" className="underline py-0 px-2 h-6" onClick={() => setIsNotificationVisible(true)}>Follow the link</Button>
                        </div>
                        <Button add kind="text" icon="icon-close-outline" onClick={() => console.log("close notification")}></Button>
                    </div>
                </AccordionItem>
            </Accordion>
            <NotificationModal visible={isNotificationVisible} closeModal={closeNotification} />
            <div className="flex">
                <Search className="pl-0 w-96"
                    onSearch={handleSearch} placeholder="Search asset name" searchValue={searchText} onChange={(e) => setSearchText(e)}
                />
                <Checkbox className="m-4" checked={isOwnedByMeChecked} onChange={ownCheckedChange}>Owned by me</Checkbox>
                <Checkbox className="m-4" checked={isUnassignedChecked} onChange={unassignedChange}>Unassigned asset</Checkbox>
            </div>
            <div className="flex gap-2 mt-4">
                <div className="text-[#696969]">{listOfAssets?.totalItems || 0} Results</div>
                {selectedFilters?.length > 0 &&
                    selectedFilters?.map((filter, index) => <Tag key={index} type='filled' onClose={() => removeFilter(filter)}>{filter}</Tag>)
                }
                <Button className="underline p-0 ml-2" kind="text" onClick={clearFilter}>Clear all</Button>
            </div>
            <div>
                {listOfAssets && listOfAssets.data && listOfAssets?.data?.length > 0 &&
                    <Table originalData={JSON.parse(JSON.stringify(listOfAssets?.data))} hasTitle>
                        <Column field='assetType' sortKey='assetType' renderCell={
                            (row) => {
                                return (
                                    <>
                                        <span className={`Appkit4-icon icon-${dataAssetsWithPreviewMapping.filter(dataAsset => dataAsset.name === row.assetType)[0]?.prefixIcon}-outline ap-font-24 px-4`} aria-hidden="true"></span>
                                        <span className="pl-2">{assetTypeOptions.filter(option => option.value === row.assetType)[0]?.label}</span>
                                    </>
                                )
                            }
                        }>Type</Column>
                        <Column field='assetName' sortKey='assetName'>Name</Column>
                        <Column field='assetOwner' sortKey='assetOwner'>Owner</Column>
                        <Column field='assetStatus' sortKey='assetStatus'
                            renderCell={(row) =>
                                <span>{AssetStatusMapping.filter((status) => status.value === row.assetStatus)[0].label}</span>
                            }>Asset Status</Column>
                        <Column onClick={() => setVisibleInfo(true)} field='surveyStatus' renderCell={(row) => {
                            const selectedStatus = surveyStatusIndicators.filter(statusDetail => statusDetail.status === row.surveyStatus)[0]
                            return (
                                <div className="flex">
                                    <Badge type={selectedStatus?.statusIndicator} value={selectedStatus?.label} />
                                    {selectedStatus?.btnText &&
                                        <div className="cursor-pointer px-2" onClick={() => console.log("button clicked")}>
                                            <Badge icon={`icon-arrow-right-small-outline`} type="primary" value={selectedStatus?.btnText} />
                                        </div>
                                    }
                                </div>
                            )
                        }}
                        >
                            <span onClick={() => setVisibleInfo(!isVisibleInfo)} className={`Appkit4-icon icon-information-outline ap-font-24 mx-2`} aria-hidden="true"></span>
                            <span>Survey Status</span>
                            {isVisibleInfo &&
                                <Notice className="absolute text-left"
                                    style={{ "zIndex": "1", "width": "30rem", "backgroundColor": "white", "boxShadow": "0 0 1rem 0" }}
                                    title='Asset status legenda'
                                    message={surveyStatusIndicators?.map((surveyStatus, index) =>
                                        <div className="flex my-4" key={index}>
                                            <Badge className="mx-1" type={surveyStatus?.statusIndicator} value={surveyStatus?.label} />
                                            <div className="mx-1">{surveyStatus?.text}</div>
                                        </div>
                                    )
                                    }
                                    status="icon-information-fill"
                                    onClose={() => setVisibleInfo(false)}
                                >
                                </Notice>
                            }
                        </Column>
                        <Column field='id' renderCell={(row) => (
                            row.assetActiveStatus &&
                                <div className="flex flex-row gap-4">
                                    <Button kind="secondary" onClick={() => {
                                        dispatch(setSelectedAsset(row));
                                        setIsManageAssetVisible(true);
                                    }}>Manage</Button>
                                    <Button kind="primary" onClick={() => navigate(`/project/${selectedProject?.id}/dataAsset/${row?.id}`)}>View details</Button>
                                </div>
                        )
                        }></Column>
                    </Table>
                }
                <ManageDataAssetModal visible={isManageAssetVisible} closeModal={closeManageAssetModal} selectedAsset={selectedAsset} />
                <div className="flex justify-between">
                    <div className="flex mt-4 gap-2 items-center">
                        <span>Show</span>
                        <Select className="!w-20"
                            placeholder="drop"
                            hideTitleOnInput={true}
                            data={itemsPerPageOptions}
                            value={selectedValue}
                            onSelect={(e) => setSelectedValue(e.toString())}
                        >
                        </Select>
                        <span>items per page</span>
                    </div>
                    <Pagination current={current} total={520} onPageChange={onPageChange}></Pagination>
                </div>
            </div>
        </>
    )
}

export default DataAssetsDetailsBusinessUser;