export const navigationItemsWithProject = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project Overview',
        prefixIcon: 'list-view',
        prefixCollapsedIcon: 'list-view',
    },
    {
        name: 'Team Members',
        prefixIcon: 'community',
        prefixCollapsedIcon: 'community',
    },
    {
        name: 'Lineage Model',
        prefixIcon: 'upload',
        prefixCollapsedIcon: 'upload',
    },
    {
        name: 'Data Assets',
        prefixIcon: 'data',
        prefixCollapsedIcon: 'data',
    },
    {
        name: 'Project Configuration',
        prefixIcon: 'cogs',
        prefixCollapsedIcon: 'cogs',
    }
];
 
export const navigationItemsWithoutProject = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    }
];
 
export const navigationItemsWithProjectManager = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project Overview',
        prefixIcon: 'list-view',
        prefixCollapsedIcon: 'list-view',
    },
    {
        name: 'Team Members',
        prefixIcon: 'community',
        prefixCollapsedIcon: 'community',
    }
];
 
export const navigationItemsWithProjectDashboardBusinessUser = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project Overview',
        prefixIcon: 'management',
        prefixCollapsedIcon: 'management',
    }
]
 
export const navigationItemsWithProjectBusinessUser = [
    {
        name: 'Home',
        prefixIcon: 'grid-view',
        prefixCollapsedIcon: 'grid-view',
    },
    {
        name: 'Project Overview',
        prefixIcon: 'management',
        prefixCollapsedIcon: 'management',
    },
    {
        name: 'Asset Details',
        prefixIcon: 'arrow-left',
        prefixCollapsedIcon: 'arrow-left',
    }
]
 
export const initialProjectDTO =
{
    "name": "",
    "type": "",
    "description": "",
    "startDate": "",
    "endDate": "",
    "id": "",
    "projectStatus": ""
}
 
export const projectRoles = [
    {
        value: "admin",
        label: "Admin"
    },
    {
        value: "migration-manager",
        label: "Manager"
    },
    {
        value: "business-user",
        label: "Business User"
    }
]
 
export const dataAssetsWithPreviewMapping = [
    {
        name: 'report',
        prefixIcon: 'document-text'
    },
    {
        name: 'sasScript',
        prefixIcon: 'query'
    },
    {
        name: 'dashboard',
        prefixIcon: 'dashboard'
    },
    {
        name: 'applications',
        prefixIcon: 'box'
    },
    {
        name: 'operationalDatafeed',
        prefixIcon: 'group'
    },
    {
        name: 'aiModel',
        prefixIcon: 'table-data'
    }
];
 
export const surveyStatusIndicators = [
    {
        "status": "ongoing",
        "statusIndicator": "warning",
        "label": "Ongoing",
        "btnText": "Continue",
        "text": "This asset's survey is being edited."
    },
    {
        "status": "inReview",
        "statusIndicator": "info",
        "label": "In review",
        "text": "This asset is being reviewed by a manager."
    },
    {
        "status": "complete",
        "statusIndicator": "success",
        "label": "Complete",
        "text": "This asset has a complete survey."
    },
    {
        "status": "notStarted",
        "statusIndicator": "danger",
        "label": "Not Started",
        "btnText": "Start",
        "text": "The asset’s survey hasn’t started."
    }
];
 
export const AssetStatusMapping = [
    {
        value: 'confirmed',
        label: 'Confirmed'
    },
    {
        value: 'pendingForReview',
        label: 'Pending For Review'
    },
    {
        value: 'removed',
        label: 'Removed'
    },
    {
        value: 'rejected',
        label: 'Rejected'
    },
]
 
export const assetTypeOptions = [
    {
        label: 'AI Model',
        value: 'aiModel'
    },
    {
        label: 'Applications',
        value: 'applications'
    },
    {
        label: 'SAS Script',
        value: 'sasScript'
    },
    {
        label: 'Dashboard',
        value: 'dashboard'
    },
    {
        label: 'Report',
        value: 'report'
    },
    {
        label: 'Operational Datafeed',
        value: 'operationalDatafeed'
    }
];
 
export const assetCriticalityOptions = [
    {
        label: 'Low',
        value: 'low'
    },
    {
        label: 'Medium',
        value: 'medium'
    },
    {
        label: 'High',
        value: 'high'
    },
    {
        label: 'Very High',
        value: 'veryHigh'
    }
];
 
export const itemsPerPageOptions = [
    {
        value: "10",
        label: "10"
    },
    {
        value: "20",
        label: "20"
    },
    {
        value: "30",
        label: "30"
    }
];
 
export const frequencyOfUseOptions = [
    {
        value: "daily",
        label: "Daily"
    }, {
        value: "monthly",
        label: "Monthly"
    },
    {
        value: "querterly",
        label: "Querterly"
    },
    {
        value: "yearly",
        label: "Yearly"
    }
];
 
export const targetAudienceOptions = [
    {
        value: "internal",
        label: "Internal"
    },
    {
        value: "external",
        label: "External"
    }
]
 
export const radioOptions = [
    {
        value: "yes",
        label: "Yes"
    },
    {
        value: "no",
        label: "No"
    },
    {
        value: "NA",
        label: "N.a."
    }
]
 
export const migrationStatusOptions = [
    {
        value: 'notStarted',
        label: 'Not Started'
    },
    {
        value: 'planned',
        label: 'Planned'
    },
    {
        value: 'onGoing',
        label: 'On Going'
    },
    {
        value: 'review',
        label: 'Review'
    },
    {
        value: 'migrated',
        label: 'Migrated'
    }
]