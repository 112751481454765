import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { AssetType } from '../../../api';

type DataAssetFormValues = {
  assetName: string;
  assetType: AssetType;
  assetDescription: string;
};

type DataAssetFormProviderProps = {
  onSubmit: SubmitHandler<DataAssetFormValues>;
  children: React.ReactNode;
};

const AddAssetFormProvider: React.FC<DataAssetFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<DataAssetFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default AddAssetFormProvider;
