import { RootState } from '../index';

export const selectProjectLoading = (state: RootState) => state.project.loading;
export const selectProjectError = (state: RootState) => state.project.error;
export const selectUserEmailsTableData = (state: RootState) => state.project.userEmailsTableData;
export const selectInitialProjectDetails = (state: RootState) => state.project.projectInitialDetails;
export const selectUsersListByRole = (state: RootState) => state.project.usersByRoleList;
export const selectAllProjectsList = (state: RootState) => state.project.allProjectsList;
export const selectUserEmailsForProject = (state: RootState) => state.project.userEmailsForProject;
export const selectSelectedProjectData = (state: RootState) => state.project.selectedProjectData;
export const selectProjectConfigData = (state: RootState) => state.project.selectedProjectConfigData;
export const selectGetAllAssetForProject = (state: RootState) => state.project.assetDataForProject;
export const selectSelectedAsset = (state: RootState) => state.project.selectedAsset;
export const selectIsEditModeAsset = (state: RootState) => state.project.isEditModeAsset;