import { Button, Modal } from "@appkit4/react-components";
import RequestFormProvider from "./RequestFormProvider";
import { ControlledTextArea } from "../../../core/components";
import { useFormContext } from "react-hook-form";

interface DeleteUserConfModalProps {
    visible: boolean;
    closeModal: Function;
    reqOwnershipOfAsset: any;
}

type RemoveOwnershipFormErrors = {
    ownerReqComment: string;
};

const RequestOwnershipModal: React.FC<DeleteUserConfModalProps> = ({ visible, closeModal, reqOwnershipOfAsset }) => {
    return (
        <Modal
            visible={visible}
            title={"Remove asset"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={reqOwnershipOfAsset}>
                <RequestOwnershipForm />
            </RequestFormProvider>
        </Modal>);
}

const RequestOwnershipForm = () => {
    const { formState: { errors } } = useFormContext<RemoveOwnershipFormErrors>();
    return (
        <div>
            <div className="font-small text-sm text-[#696969]">If this asset belongs to you, please click 'Request Ownership' and provide a justification in the comment area.</div>
            <div className="my-4">
                <ControlledTextArea name="reqOwnerComment" required={true} label="Comment" error={errors.ownerReqComment} maxLength={420} />
                {errors.ownerReqComment && <p className="!text-red-500 mt-2">{errors.ownerReqComment.message}</p>}
            </div>
            <div className="my-4 flex justify-end">
                <Button kind="primary" icon="icon-lockopen-unlock-outline" type="submit">Request ownership</Button>
            </div>
        </div>
    )
}

export default RequestOwnershipModal;