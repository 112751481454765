import { Button, Modal } from "@appkit4/react-components";
import { useFormContext } from "react-hook-form";
import { ControlledInput } from "../../../core/components";
import { useEffect } from "react";
import ProjectConfigFormProvider from "./ProjectConfigFormProvider";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { ProjectConfigDTO } from "../../../api";
import { editProjectConfigDetails } from "../../../store/project/projectThunks";

interface EditProjectConfModalProps {
    visible: boolean;
    closeModal: Function;
    configData: ProjectConfigDTO;
}

type EditProjectFormErrors = {
    gcsBucket: string;
    folder: string;
    serviceAccount: string;
};

const EditProjectConfigModal: React.FC<EditProjectConfModalProps> = ({ visible, closeModal, configData }) => {
    const dispatch = useDispatch<AppDispatch>();
    const editProjectConfigData = (data: {
        gcsBucket: string;
        folder: string;
        serviceAccount: string;
    }) => {
        closeModal();
        const reqPayload: ProjectConfigDTO = {
            projectId: configData?.projectId,
            config: {
                gcsBucket: data?.gcsBucket,
                folder: data?.folder,
                serviceAccount: data?.serviceAccount
            }
        }
        dispatch(editProjectConfigDetails(reqPayload));
    };
    return (
        <Modal
            visible={visible}
            title={"Configurations"}
            onCancel={closeModal}
            modalStyle={{ width: '28rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <ProjectConfigFormProvider onSubmit={editProjectConfigData}>
                <ModalContent closeModal={closeModal} configData={configData}/>
            </ProjectConfigFormProvider>
        </Modal>);
}

const ModalContent:React.FC<{closeModal: Function, configData: any}> = ({closeModal, configData}) => {
    const { formState: { errors }, setValue } = useFormContext<EditProjectFormErrors>();
    useEffect(() => {
        setValue("gcsBucket", configData?.config?.gcsBucket);
        setValue("folder", configData?.config?.folder);
        setValue("serviceAccount", configData?.config?.serviceAccount);
    },[setValue, configData]);
    return(
        <div>
            <div className="my-4">
                <ControlledInput name="gcsBucket" required={true} type="text" label="GCS Bucket" error={errors.gcsBucket} />
                {errors.gcsBucket && <p className="!text-red-500 mt-2">{errors.gcsBucket.message}</p>}
            </div>
            <div className="my-4">
                <ControlledInput name="folder" required={true} type="text" label="Folder" error={errors.folder} />
                {errors.folder && <p className="!text-red-500 mt-2">{errors.folder.message}</p>}
            </div>
            <div className="my-4">
                <ControlledInput name="serviceAccount" required={true} type="text" label="Service Account" error={errors.serviceAccount} />
                {errors.serviceAccount && <p className="!text-red-500 mt-2">{errors.serviceAccount.message}</p>}
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={closeModal}>Cancel</Button>
                <Button kind='primary' icon="icon-check-hook-outline" type="submit">Save</Button>
            </div>
        </div>
    )
}

export default EditProjectConfigModal;