import { Badge, Button, Notification, Panel } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import RemovalConfirmationModal from "./RemovalConfirmationModal";
import EditableFieldsDataAsset from "./EditableFieldsDataAsset";
import DataAssetFieldsFormProvider from "./DataAssetFieldsFormProvider";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsEditModeAsset, selectSelectedAsset } from "../../../store/project/projectSelectors";
import { assetCriticalityOptions, assetTypeOptions, migrationStatusOptions } from "../../../services/constants";
import { setIsEditModeAsset } from "../../../store/project/projectSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import RequestOwnershipModal from "./RequestOwnershipModal";
import { KeycloakService } from "../../../services/KeycloakService";
import { getAssetDetails, reqToRemoveAssetOwnership, requestForAssetOwnership, updateDataAssetDetails } from "../../../store/project/projectThunks";
import dayjs from "dayjs";

const DataAssetInfoBusinessUser: React.FC = () => {
    const [isRemovalModalShow, setIsRemovalModalShow] = useState(false);
    const [isRequestOwnerModalShow, setIsReqOwnerModalShow] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { dataAssetId } = useParams();
    const selectedAsset = useSelector(selectSelectedAsset);
    useEffect(() => {
        dispatch(getAssetDetails(dataAssetId || ""));
    }, [dispatch, dataAssetId]);
    const assetDetails = {
        "Asset type": assetTypeOptions?.filter(option => option.value === selectedAsset?.assetType)[0]?.label,
        "Asset description": selectedAsset?.assetDescription,
        "Created by": selectedAsset?.createdBy,
        "Created date": selectedAsset?.createdAt ? dayjs(new Date(selectedAsset.createdAt)).format("MM/DD/YYYY") : "",
        "Updated by": selectedAsset?.updatedBy,
        "Updated date": selectedAsset?.updatedAt ? dayjs(new Date(selectedAsset?.updatedAt)).format("MM/DD/YYYY") : "",
        "criticality": assetCriticalityOptions?.filter(option => option.value === selectedAsset?.assetCriticality)[0]?.label,
        "Environment": selectedAsset?.environment,
        "Migration status": migrationStatusOptions?.filter(option => option.value === selectedAsset?.migrationStatus)[0]?.label,
        "Use case": selectedAsset?.useCase
    }
    const isEditMode = useSelector(selectIsEditModeAsset);
    const closeRemovalModal = () => {
        setIsRemovalModalShow(false);
    }
    const closeReqOwnerModal = () => {
        setIsReqOwnerModalShow(false);
    }
    const removeAsset = (data: any) => {
        dispatch(reqToRemoveAssetOwnership({ assetId: selectedAsset?.id || "", requestDescription: data?.reqOwnerRemovalComment }))
        setIsRemovalModalShow(false);
    };
    const reqOwnershipOfAsset = (data: any) => {
        dispatch(requestForAssetOwnership({ assetId: selectedAsset?.id || "", requestDescription: data?.reqOwnerComment }))
        setIsReqOwnerModalShow(false);
    };
    const editDataAsset = (data: any) => {
        const payload = {
            id: selectedAsset.id || "",
            assetName: data.assetName,
            assetType: data.assetType,
            assetDescription: data.assetDescription,
            assetCriticality: data.assetCriticality,
            devEnvCompleted: data.isDevMigrated,
            devEnvCompletedDate: data.isDevMigrated === "yes" ? new Date(`${data.devMigrationDate} UTC`).toISOString() : undefined,
            dataSourcesMigrated: data.isDataSourcesMigrated,
            dataSourcesMigratedDate: data.isDataSourcesMigrated === "yes" ? new Date(`${data.dataSourcesMigrationDate} UTC`).toISOString() : undefined,
            uatEnvCompleted: data?.isUatMigrated,
            uatEnvCompletedDate: data?.isUatMigrated === "yes" ? new Date(`${data?.uatMigrationDate} UTC`).toISOString() : undefined,
            prePodEnvCompleted: data?.isPreProductionMigrated,
            prePodEnvCompletedDate: data?.isPreProductionMigrated === "yes" ? new Date(`${data?.preProductionMigrationDate} UTC`).toISOString() : undefined,
            prodEnvCompleted: data?.isProductionMigrated,
            prodEnvCompletedDate: data?.isProductionMigrated === "yes" ? new Date(`${data?.productionMigrationDate} UTC`).toISOString() : undefined,
            assetComplexity: data?.complexity,
            frequencyOfUse: data?.frequency,
            targetAudience: data?.targetAudience
        }
        dispatch(setIsEditModeAsset(false));
        dispatch(updateDataAssetDetails(payload));
    }
    return (
        <>
            <div className="flex flex-row mt-4">
                <span className="Appkit4-icon icon-query-fill ap-font-16 pt-1 text-[#415385]" aria-hidden="true"></span>
                <h1 className="font-sans font-medium text-xl text-[#415385]">EMPLOYEES_LIST</h1>
            </div>
            <div className="my-4">
                <Notification
                    title='Notification'
                    className="w-full m-0"
                    message={'Ownership of the asset has been denied because the current owner has more relevant expertise and responsibility for its management, ensuring continuity and accuracy in the migration process.'}
                    status=''
                    icon={<span className="Appkit4-icon icon-notifications-alert-fill ap-font-16 pt-1 text-[#FFBF1F]" aria-hidden="true"></span>}
                />
            </div>
            <div className="my-2">
                <Panel title="Ownership of the asset" >
                    <div className="flex flex-row gap-2 justify-between">
                        <span>If this asset belongs to you, please click 'Request Ownership' and provide a justification in the comment area. Once ownership is confirmed, you will be able to edit the asset, request its removal if necessary, and proceed to fill out the asset's survey.</span>
                        <Button kind="primary" disabled={selectedAsset.owner === KeycloakService.getEmail()} icon="icon-lockopen-unlock-outline" onClick={() => setIsReqOwnerModalShow(true)}>Request ownership</Button>
                    </div>
                </Panel>
                <RequestOwnershipModal visible={isRequestOwnerModalShow} closeModal={closeReqOwnerModal} reqOwnershipOfAsset={reqOwnershipOfAsset} />
            </div>
            <div className="my-4 bg-white">
                <div className="flex justify-between">
                    <div className="flex flex-row gap-2 p-6 items-center">
                        <div className="font-sans font-medium text-xl">Asset survey</div>
                        <Badge value={'Not started'} type={'danger'} />
                        <span className="Appkit4-icon icon-calendar-clock-outline ap-font-16 pt-1" aria-hidden="true"></span>
                        <span>Survey deadline by xx.xx.xxxx</span>
                    </div>
                    <Button className="m-6" kind="primary" icon="icon-document-text-outline" onClick={() => console.log("starting survey")}>Start survey</Button>
                </div>
            </div>
            <div className="my-4">
                <Panel title="Details">
                    {!isEditMode ?
                        <>
                            {Object.entries(assetDetails).map(([key, value]) => (
                                <div className="flex justify-between bg-[#F3F3F3] border p-2" key={key}>
                                    <div>{key}</div>
                                    <div>{value}</div>
                                </div>
                            ))}

                        </>
                        :
                        <DataAssetFieldsFormProvider onSubmit={editDataAsset}>
                            <EditableFieldsDataAsset selectedAsset={selectedAsset} />
                        </DataAssetFieldsFormProvider>
                    }
                    <div className="flex justify-end gap-2 mt-4">
                        <Button kind='primary' disabled={isEditMode || selectedAsset.owner !== KeycloakService.getEmail()} icon="icon-pencil-outline" onClick={() => dispatch(setIsEditModeAsset(true))}>Edit</Button>
                    </div>
                </Panel>
            </div>
            <div className="my-2">
                <Panel title="Remove asset" >
                    <div className="flex flex-row gap-2 justify-between">
                        <span className="text-sm">If you have a reason for this asset to be removed, please write it in the comments and inform the manager.</span>
                        <Button kind="primary" disabled={selectedAsset.owner !== KeycloakService.getEmail()} icon="icon-delete-outline" onClick={() => setIsRemovalModalShow(true)}>Request removal</Button>
                    </div>
                </Panel>
                <RemovalConfirmationModal visible={isRemovalModalShow} closeModal={closeRemovalModal} removeAsset={removeAsset} />
            </div>
        </>
    )
}

export default DataAssetInfoBusinessUser;