export const typeData = [
    { value: 'type1', label: 'type1' },
    { value: 'type2', label: 'type2' },
    { value: 'type3', label: 'type3' },
    { value: 'type4', label: 'type4' },
    { value: 'dmw-type', label: 'dmw-type'}
];

export const multiSelectData = [
    { value: 'email1@pwc.com', label: 'email1@pwc.com' },
    { value: 'email2@pwc.com', label: 'email2@pwc.com' },
    { value: 'email3@others.com', label: 'email3@others.com' },
    { value: 'email4@others.com', label: 'email4@others.com' },
    { value: 'email5@pwc.com', label: 'email4@pwc.com' }
];

export const projectDetailsData = [
    {
        "id": "1",
        "name": "New Project 1",
        "type": "Project Type",
        "description": "Project Description",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "projectStatus": "ongoing"
    },
    {
        "id": "2",
        "name": "New Project 2",
        "type": "Project Type new",
        "description": "Project Description new",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "projectStatus": "ongoing"
    },
    {
        "id": "3",
        "name": "New Project 3",
        "type": "Project Type new12",
        "description": "Project Description new12",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "projectStatus": "Not started"
    }
];

export const archiveProjectData = [
    {
        "name": "New Project 1",
        "type": "Project Type",
        "description": "Project Description",
        "ownerId": "e278f9fc-1835-4145-bcc8-06ff588660ce",
        "adminList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "managerList": [
            "e278f9fc-1835-4145-bcc8-06ff588660ce"
        ],
        "startDate": "2024-08-20T09:49:21.309Z",
        "endDate": "2024-08-20T09:49:21.309Z",
        "status": "Archieved"
    }
];

export const dataAssetstobeMigrated = [
    {
        name: "Reports",
        icon: "bar-chart",
        noOfAssets: "1543"
    },
    {
        name: "Scripts",
        icon: "query",
        noOfAssets: "403"
    },
    {
        name: "Dashboards",
        icon: "dashboard",
        noOfAssets: "324"
    },
    {
        name: "Applications",
        icon: "box",
        noOfAssets: "25"
    },
    {
        name: "Applications",
        icon: "table-data",
        noOfAssets: "34682"
    }
]

export const listOfUsersMock = [
    {
        "users": [
            {
                "id": "9e9-4776-9398-4bd7f21dcc1d",
                "email": "vishal@gmail.com",
                "role": "admin",
                "active": true
            }
        ],
        "role": "admin",
        "count": 1
    },
    {
        "users": [
            {
                "id": "097-4dcf-94b8-783d8d37dc9d",
                "email": "soumadip@gmail.com",
                "role": "migration-manager",
                "active": true
            }
        ],
        "role": "migration-manager",
        "count": 1
    }
]

export const mockConfigData = {
  "projectId": "string",  
  "config": {    
    "gcsBucket": "bucketId",    
    "folder": "new_folder",    
    "serviceAccount": "accolunt1"  
  }
}

export const mockDataAssetsTableData = [
    {
        id: "1",
        assetType: "Report",
        assetName: "asset name1",
        assetOwner: "abc@xyz.com",
        surveyStatus: "ongoing",
    },
    {
        id: "2",
        assetType: "Script",
        assetName: "asset name2",
        assetOwner: "abcd@xyz.com",
        surveyStatus: "notStarted",
    },
    {
        id: "3",
        assetType: "Dashboard",
        assetName: "asset name3",
        assetOwner: "abcdef@xyz.com",
        surveyStatus: "inReview",
    },
    {
        id: "4",
        assetType: "Application",
        assetName: "asset name4",
        assetOwner: "bdc@xyz.com",
        surveyStatus: "ongoing",
    },
    {
        id: "5",
        assetType: "Table",
        assetName: "asset name5",
        assetOwner: "absddfsc@xyz.com",
        surveyStatus: "notStarted",
    },
    {
        id: "6",
        assetType: "Report",
        assetName: "asset name6",
        assetOwner: "abcasdwed@xyz.com",
        surveyStatus: "complete",
    },
    {
        id: "7",
        assetType: "Table",
        assetName: "asset name7",
        assetOwner: "ghy@xyz.com",
        surveyStatus: "notStarted",
    },
    {
        id: "8",
        assetType: "Dashboard",
        assetName: "asset name8",
        assetOwner: "bgy@xyz.com",
        surveyStatus: "ongoing",
    }
]

export const assetDetail = {
    "Asset type": "script",
    "Asset description": "This is an asset description. This can be anything",
    "Created by": "John Doe",
    "Created date": "25/03/2021",
    "Updated by": "Max Masterman",
    "Updated date": "12/04/2022",
    "criticality": "Medium",
    "Environment": "-",
    "Migration status": "-",
    "Use case": "-"
}