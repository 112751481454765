import { useFormContext } from "react-hook-form";
import { ControlledInput, ControlledTextArea, ControlledDatePicker } from "../../../core/components";
import { Button, Select } from '@appkit4/react-components';
import UsersTable from "./UsersTable";
import AddUserModal from "./AddUserModal";
import { useEffect, useMemo, useState } from "react";
import { typeData } from "../mockData";
import { setProjectInitialDetails, setUserEmailsTableData } from "../../../store/project/projectSlice";
import { AppDispatch } from '../../../store';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUserEmailsTableData } from "../../../store/project/projectSelectors";
import { KeycloakService } from "../../../services/KeycloakService";
import ProjectFormProvider from "./ProjectFormProvider";
import { useNavigate } from "react-router-dom";
import { getOrgName, removeDuplicates } from "../../../core/util/utility";

type ProjectFormErrors = {
    name: string;
    type: string;
    startDate: string;
    endDate: string;
    description: string;
    adminEmails: string;
    managerEmails: string;
};

const ProjectDetailsComp: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [startDateValue, setStartdateValue] = useState();
    const [endDateValue, setEndDateValue] = useState(); 
    const isAdmin = KeycloakService.isAuthorizedRole("admin");
    const email = KeycloakService.getEmail();
    const orgName = KeycloakService.getOrganizationName();
    const userData = useMemo(() => {
        return {
            email: email,
            company: orgName,
            role: isAdmin ? "admin" : ""
        }
    }, [isAdmin, email, orgName]);

    useEffect(() => {
        dispatch(setUserEmailsTableData([userData]));
    }, [dispatch, userData])

    const tableData = JSON.parse(JSON.stringify(useSelector(selectUserEmailsTableData)));
    const { formState: { errors } } = useFormContext<ProjectFormErrors>();
    const { getValues } = useFormContext();
    const [visible, setVisible] = useState(false);
    const addUser = () => {
        setVisible(true);
    }
    const closeModal = () => {
        setVisible(false);
    }

    const addUsers = () => {
        const oldData = tableData;
        const createManagerEmailsArray: Array<any> = [];
        getValues().managerEmails?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "migration-manager" };
            createManagerEmailsArray.push(arrayElement);
        });
        getValues().adminEmails?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "admin" };
            createManagerEmailsArray.push(arrayElement);
        });
        oldData.push(...createManagerEmailsArray);
        const uniqueElements = removeDuplicates(oldData, 'email');
        dispatch(setUserEmailsTableData(uniqueElements));
        setVisible(false);
    }
    const cancelProject = () => {
        navigate("/dashboard");
    }
    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            <h1 className="font-sans font-bold font-medium text-2xl py-4">Enter project details</h1>
            <div className="p-8 bg-white">
                <div className="w-2/3">
                    <ControlledInput name="name" required={true} type="name" label="Name" error={errors.name} />
                    {errors.name && <p className="text-red-500 mt-2">{errors.name.message}</p>}
                </div>
                <div className="w-2/3 flex mb-4 mt-4 sm:flex-col lg:flex-row gap-2">
                    <div className="w-full">
                        {/* <ControlledDropdown name="type" label="Type" error={errors.type} data={typeData} disabled={true} />
                        {errors.type && <p className="text-red-500 mt-2">{errors.type.message}</p>} */}
                        <Select disabled value="dmw-type" placeholder="Type" data={typeData} />
                    </div>
                    <div className="w-full">
                        <ControlledDatePicker name="startDate" required={true} label="Start Date" 
                            error={errors.startDate} onChange={setStartdateValue} maxDate={endDateValue ? endDateValue : undefined}/>
                        {errors.startDate && <p className="text-red-500 mt-2">{errors.startDate.message}</p>}
                    </div>
                    <div className="w-full">
                        <ControlledDatePicker name="endDate" required={true} label="End Date" 
                            error={errors.endDate} onChange={setEndDateValue} minDate={startDateValue ? startDateValue : undefined}/>
                        {errors.endDate && <p className="text-red-500 mt-2">{errors.endDate.message}</p>}
                    </div>
                </div>
                <div className="w-2/3">
                    <ControlledTextArea name="description" required={true} label="Description" error={errors.description} maxLength={420} />
                    {errors.description && <p className="text-red-500 mt-2">{errors.description.message}</p>}
                </div>
                <div className="py-4">
                    <div className="flex justify-between">
                        <div>
                            <h3 className="font-medium text-xl">Define team</h3>
                            <h5>Please, select the users for this project team. You can invite admins and migration managers.</h5>
                        </div>
                        <Button className="my-4 flex justify-end" kind='secondary' icon="icon-plus-outline" onClick={addUser}>Add User</Button>
                    </div>
                    <AddUserModal visible={visible} closeModal={closeModal} addUsers={addUsers} />
                    <UsersTable />
                </div>
                <div className="flex justify-end gap-2">
                    <Button kind='secondary' onClick={cancelProject}>Cancel</Button>
                    <Button kind='primary' icon="icon-check-hook-outline" type="submit">Next</Button>
                </div>
            </div>
        </div>
    )
}

const ProjectDetails: React.FC<{ setActiveIndex: Function }> = ({ setActiveIndex }) => {
    const dispatch = useDispatch<AppDispatch>();
    const emailsListTableData = JSON.parse(JSON.stringify(useSelector(selectUserEmailsTableData)));
    const adminEmails = emailsListTableData.filter((data:any) => data.role === "admin").map((listData: any) => listData?.email);
    const managerEmails = emailsListTableData.filter((data:any) => data.role === "migration-manager").map((listData: any) => listData?.email);
    const handleSubmit = (data: {
        name: string;
        type: string;
        startDate: Date;
        endDate: Date;
        description: string;
    }) => {
        const projectDetails = {
            name: data?.name,
            type: "dmw-type",
            startDate: new Date(`${data?.startDate} UTC`).toISOString(),
            endDate: new Date(`${data?.endDate} UTC`).toISOString(),
            description: data?.description,
            adminEmails: adminEmails,
            managerEmails: managerEmails
        }
        dispatch(setProjectInitialDetails(projectDetails));
        setActiveIndex(1);
    };
    return (
        <ProjectFormProvider onSubmit={handleSubmit}>
            <ProjectDetailsComp />
        </ProjectFormProvider>
    )
}
export default ProjectDetails;