import { createAsyncThunk } from '@reduxjs/toolkit';
import { addTeamsToProject, createAsset, createProject, getAllAssets, getAllProjects, getAllUsers, getAsset, getProject, getProjectConfig, removeAssetOwnership, removeMemberFromProject, requestAssetOwnership, updateAssetOfaProject, updateMemberOfaProject, updateProject, updateProjectConfig, updateProjectStatus } from '../../api/services.gen';
import { AssetRequest, CreateAssetRequest, CreateProjectRequest, CreateTeamsRequest, GetAssetRequest, ProjectConfigUpdateRequest, UpdateAssetRequest, UpdateMemberRequest, UpdateProjectRequest, UpdateProjectStatusRequest } from '../../api/types.gen';
import { setLoading, setError, setUsersByRole, setAllProjectsList, setSelectedProjectData, setProjectConfigData, setAssetDataForProject, setSelectedAsset } from './projectSlice';

export const createNewProject = createAsyncThunk(
  'project/create',
  async (projectData: CreateProjectRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await createProject({ body: projectData });
      if (response.data?.id) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to create project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getProjectsList = createAsyncThunk(
  'project/get',
  async (_undefined, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllProjects();
      if (response?.data) {
        dispatch(setAllProjectsList(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project List'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getProjectDetails = createAsyncThunk(
  '/project/{id}',
  async (id: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getProject({ path: { id: id } });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
export const getUsers = createAsyncThunk(
  'user/getUsers',
  async (_undefined, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAllUsers();
      if (response.data) {
        dispatch(setUsersByRole(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get the users list data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const archiveProject = createAsyncThunk(
  'project/{id}/status',
  async (statusBody: UpdateProjectStatusRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProjectStatus({ path: { id: statusBody?.id }, body: statusBody });
      if (response.data?.id) {
        dispatch(setError(null));
        dispatch(setSelectedProjectData(response.data));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update project as archive'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const EditProjectData = createAsyncThunk(
  'project/{id}',
  async (projectData: UpdateProjectRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProject({ path: { id: projectData?.id }, body: projectData });
      if (response.data?.id) {
        dispatch(setError(null));
        dispatch(setSelectedProjectData(response.data));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update project data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getProjectConfigData = createAsyncThunk(
  'project/{id}/config',
  async (id: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getProjectConfig({ path: { id: id } });
      if (response?.data) {
        dispatch(setProjectConfigData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get Project Config details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editProjectConfigDetails = createAsyncThunk(
  'project/{id}/config',
  async (projectConfigData: ProjectConfigUpdateRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateProjectConfig({ path: { id: projectConfigData?.projectId }, body: projectConfigData });
      if (response?.data) {
        dispatch(setProjectConfigData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update Project Config details'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const removeProjectMember = createAsyncThunk(
  '/project/{id}/member/{userEmail}',
  async (userEmailData: { projectId: string, userEmail: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await removeMemberFromProject({ path: { id: userEmailData?.projectId, email: userEmailData?.userEmail } });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to delete member from Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addProjectMember = createAsyncThunk(
  '/project/{id}/teams',
  async (teamsData: CreateTeamsRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await addTeamsToProject({ path: { id: teamsData?.id }, body: teamsData });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to add member to Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editProjectMemberRole = createAsyncThunk(
  '/project/{id}/member/{userEmail}',
  async (memberUpdateData: UpdateMemberRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateMemberOfaProject({ path: { id: memberUpdateData?.id, email: memberUpdateData?.userEmail }, body: memberUpdateData });
      if (response?.data) {
        dispatch(setSelectedProjectData(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update member role in Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addNewAssetInProject = createAsyncThunk(
  '/asset',
  async (assetData: CreateAssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await createAsset({ body: assetData });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to add new asset to Project'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAssetTableData = createAsyncThunk(
  '/asset?projectId={projectId}&searchString={searchString}&isOwnedByMeChecked={isOwnedByMeChecked}&isUnassignedChecked={isUnassignedChecked}&pageNumber={pageNumber}&pageSize={pageSize}',
  async (getAssetRequestPayload: GetAssetRequest, { dispatch }) => {
    try {
      const { searchString, isOwnedByMeChecked, isUnassignedChecked, pageNumber, pageSize, projectId } = getAssetRequestPayload;
      dispatch(setLoading(true));
      const response = await getAllAssets({ query: { projectId: projectId, searchString: searchString, isOwnedByMeChecked: isOwnedByMeChecked, isUnassignedChecked: isUnassignedChecked, pageNumber: pageNumber, pageSize: pageSize } });
      if (response?.data) {
        dispatch(setAssetDataForProject(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get asset table data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const requestForAssetOwnership = createAsyncThunk(
  '/asset/{id}/request/request-ownership',
  async (assetReq: AssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await requestAssetOwnership({ path: { id: assetReq?.assetId }, body: assetReq });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to assign ownership of the asset'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const reqToRemoveAssetOwnership = createAsyncThunk(
  '/asset/{id}/request/remove-ownership',
  async (assetReq: AssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await removeAssetOwnership({ path: { id: assetReq?.assetId }, body: assetReq });
      if (response?.data) {
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to remove ownership of the asset'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getAssetDetails = createAsyncThunk(
  '/asset/{id}',
  async (assetId: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getAsset({ path: { id: assetId } });
      if (response?.data) {
        dispatch(setSelectedAsset(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to get asset table data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateDataAssetDetails = createAsyncThunk(
  '/asset/{id}',
  async (assetData: UpdateAssetRequest, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await updateAssetOfaProject({ path: { id: assetData?.id }, body: assetData });
      if (response?.data) {
        dispatch(setSelectedAsset(response?.data));
        dispatch(setError(null));
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      dispatch(setError('Failed to update asset data'));
    } finally {
      dispatch(setLoading(false));
    }
  }
);