import { useFormContext } from "react-hook-form";
import { ControlledDatePicker, ControlledDropdown, ControlledInput } from "../../../core/components";
import { assetCriticalityOptions, assetTypeOptions, frequencyOfUseOptions, migrationStatusOptions, radioOptions, targetAudienceOptions } from "../../../services/constants";
import { Accordion, AccordionItem, Button } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { AssetDTO } from "../../../api";
import ControlledRadioGroup from "../../../core/components/ControlledRadioGroup";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { setIsEditModeAsset } from "../../../store/project/projectSlice";
import dayjs from "dayjs";

interface DataAssetFieldsProps {
    selectedAsset: AssetDTO
}

type AssetFormFieldsErrors = {
    assetName: string;
    assetType: string;
    assetCriticality: string;
    devMigrationDate: string;
    dataSourcesMigrationDate: string;
    uatMigrationDate: string;
    preProductionMigrationDate: string;
    productionMigrationDate: string;
};

const EditableFieldsDataAsset: React.FC<DataAssetFieldsProps> = ({ selectedAsset }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { formState: { errors } } = useFormContext<AssetFormFieldsErrors>();
    const { setValue, getValues } = useFormContext();
    const [activeKeys, setActiveKeys] = useState<string[]>([]);
    const onClickOptionalDetails = (activeKeys: string[]) => {
        setActiveKeys(activeKeys);
    }
    useEffect(() => {
        setValue("assetName", selectedAsset?.assetName || "");
        setValue("assetType", selectedAsset?.assetType || "");
        setValue("assetDescription", selectedAsset?.assetDescription);
        setValue("createdBy", selectedAsset?.createdBy);
        setValue("createdDate", selectedAsset?.createdAt ? dayjs(new Date(selectedAsset.createdAt)).format("MM/DD/YYYY") : "");
        setValue("updatedBy", selectedAsset?.updatedBy);
        setValue("updatedDate", selectedAsset.updatedAt ? dayjs(new Date(selectedAsset.updatedAt)).format("MM/DD/YYYY") : "");
        setValue("assetCriticality", selectedAsset?.assetCriticality);
        setValue("environment", selectedAsset?.environment);
        setValue("migrationStatus", migrationStatusOptions.filter((option) => option.value === selectedAsset?.migrationStatus)[0].label);
        setValue("useCase", selectedAsset?.useCase);
        setValue("isDevMigrated", selectedAsset?.devEnvCompleted || radioOptions[1].value);
        setValue("isDataSourcesMigrated", selectedAsset?.dataSourcesMigrated || radioOptions[1].value);
        setValue("isUatMigrated", selectedAsset?.uatEnvCompleted || radioOptions[1].value);
        setValue("isPreProductionMigrated", selectedAsset?.prePodEnvCompleted || radioOptions[1].value);
        setValue("isProductionMigrated", selectedAsset?.prodEnvCompleted || radioOptions[1].value);
        setValue("devMigrationDate", selectedAsset.devEnvCompletedDate ? dayjs(new Date(selectedAsset.devEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("dataSourcesMigrationDate", selectedAsset.dataSourcesMigratedDate ? dayjs(new Date(selectedAsset.dataSourcesMigratedDate)).format("MM/DD/YYYY") : new Date());
        setValue("uatMigrationDate", selectedAsset.uatEnvCompletedDate ? dayjs(new Date(selectedAsset.uatEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("preProductionMigrationDate", selectedAsset.prePodEnvCompletedDate ? dayjs(new Date(selectedAsset.prePodEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("productionMigrationDate", selectedAsset.prodEnvCompletedDate ? dayjs(new Date(selectedAsset.prodEnvCompletedDate)).format("MM/DD/YYYY") : new Date());
        setValue("complexity", selectedAsset.assetComplexity);
        setValue("frequency", selectedAsset.frequencyOfUse);
        setValue("targetAudience", selectedAsset.targetAudience);
    }, [setValue, selectedAsset]);
    return (
        <div className="bg-[#F3F3F3] p-4">
            <div>
                <h1 className="font-sans font-medium text-xl">Asset details</h1>
                <div className="my-4">
                    <ControlledInput name="assetName" required={true} type="text" label="Asset name" error={errors.assetName} />
                    {errors.assetName && <p className="!text-red-500 mt-2">{errors.assetName.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledDropdown name="assetType" label="Asset type" error={errors.assetType} data={assetTypeOptions} required={true} />
                    {errors.assetType && <p className="!text-red-500 mt-2">{errors.assetType.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledInput name="assetDescription" required={false} type="text" label="Asset description" />
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="createdBy" required={false} type="text" label="Created By" />
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="createdDate" required={false} type="text" label="Created Date" />
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="updatedBy" required={false} type="text" label="Updated By" />
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="updatedDate" required={false} type="text" label="Updated Date" />
                </div>
                <div className="my-4">
                    <ControlledDropdown name="assetCriticality" label="Criticality" error={errors.assetCriticality} data={assetCriticalityOptions} required={true} />
                    {errors.assetCriticality && <p className="!text-red-500 mt-2">{errors.assetCriticality.message}</p>}
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="environment" required={false} type="text" label="Environment" />
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="migrationStatus" required={false} type="text" label="Migration status" />
                </div>
                <div className="my-4">
                    <ControlledInput disabled={true} name="useCase" required={false} type="text" label="Use Case" />
                </div>
            </div>
            <Accordion multiple={false} onClick={onClickOptionalDetails} activeKeys={activeKeys}>
                <AccordionItem className="font-medium text-xl" title={"Optional details"} itemKey="1">
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Development environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isDevMigrated" required={true} options={radioOptions} value={getValues().isDevMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="devMigrationDate" disabled={getValues().isDevMigrated !== "yes"} required={getValues().isDevMigrated === "yes"} label="Development environment migrated"
                                error={errors.devMigrationDate} />
                            {errors.devMigrationDate && <p className="text-red-500 mt-2">{errors.devMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Data sources migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isDataSourcesMigrated" required={true} options={radioOptions} value={getValues().isDataSourcesMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="dataSourcesMigrationDate" disabled={getValues().isDataSourcesMigrated !== "yes"} required={getValues().isDataSourcesMigrated === "yes"} label="Data sources migrated"
                                error={errors.dataSourcesMigrationDate} />
                            {errors.dataSourcesMigrationDate && <p className="text-red-500 mt-2">{errors.dataSourcesMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            UAT environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isUatMigrated" required={true} options={radioOptions} value={getValues().isUatMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="uatMigrationDate" disabled={getValues().isUatMigrated !== "yes"} required={getValues().isUatMigrated === "yes"} label="UAT environment migrated"
                                error={errors.uatMigrationDate} />
                            {errors.uatMigrationDate && <p className="text-red-500 mt-2">{errors.uatMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Pre-production environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isPreProductionMigrated" required={true} options={radioOptions} value={getValues().isPreProductionMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="preProductionMigrationDate" disabled={getValues().isPreProductionMigrated !== "yes"} required={getValues().isPreProductionMigrated === "yes"} label="Pre-production environment migrated"
                                error={errors.preProductionMigrationDate} />
                            {errors.preProductionMigrationDate && <p className="text-red-500 mt-2">{errors.preProductionMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-2">
                        <div className="w-1/3 text-base text-[#696969]">
                            Production environment migrated
                        </div>
                        <div className="w-1/3">
                            <ControlledRadioGroup name="isProductionMigrated" required={true} options={radioOptions} value={getValues().isProductionMigrated} />
                        </div>
                        <div className="w-1/3">
                            <ControlledDatePicker onChange={() => console.log("Called")} name="productionMigrationDate" disabled={getValues().isProductionMigrated !== "yes"} required={getValues().isProductionMigrated === "yes"} label="Production environment migrated"
                                error={errors.productionMigrationDate} />
                            {errors.productionMigrationDate && <p className="text-red-500 mt-2">{errors.productionMigrationDate.message}</p>}
                        </div>
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="complexity" label="Complexity" data={assetCriticalityOptions} required={false} />
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="frequency" label="Frequency of use" data={frequencyOfUseOptions} required={false} />
                    </div>
                    <div className="my-4">
                        <ControlledDropdown name="targetAudience" label="Target Audience" data={targetAudienceOptions} required={false} />
                    </div>
                </AccordionItem>
            </Accordion>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={() => dispatch(setIsEditModeAsset(false))}>Cancel</Button>
                <Button kind='primary' disabled={!selectedAsset?.assetActiveStatus} icon="icon-check-hook-outline" type="submit">{selectedAsset?.assetActiveStatus ? 'Submit' : 'Waiting for approval'}</Button>
            </div>
        </div>
    )
}

export default EditableFieldsDataAsset;