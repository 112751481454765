import { Input } from '@appkit4/react-components';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

type ControlledInputProps = {
  name: string;
  type?: string;
  label?: string;
  error?: FieldError,
  required?: boolean,
  disabled?: boolean
};

const ControlledInput: React.FC<ControlledInputProps> = ({ name, type = 'text', label = "", error, required = false, disabled = false }) => {
  const { control } = useFormContext();
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={required ? { required: `Missing ${label.toLowerCase()}`} : {}}
        render={({ field }) => <Input 
        className={classNames({
            'error': error
        })}
        disabled={disabled}
        required={required}
        id={name} title={label} type={type} {...field} />}
      /> 
    </div>
  );
};

export default ControlledInput;
