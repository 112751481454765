import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

type RequestFormValues = {
    comment: string;
};

type RequestFormProviderProps = {
  onSubmit: SubmitHandler<RequestFormValues>;
  children: React.ReactNode;
};

const RequestFormProvider: React.FC<RequestFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<RequestFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default RequestFormProvider;
