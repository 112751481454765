import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { AssetComplexity, AssetCriticality, AssetType, FrequencyOfUse, MigratedEnvStatus, TargetAudience } from '../../../api';

type DataAssetFieldsFormValues = {
  assetName: string;
  assetType: AssetType;
  assetDescription: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  assetCriticality: AssetCriticality;
  environment: string;
  migrationStatus: string;
  useCase: string;
  isDevMigrated: MigratedEnvStatus;
  devMigrationDate: Date;
  isDataSourcesMigrated: MigratedEnvStatus;
  dataSourcesMigrationDate: Date;
  isUatMigrated: MigratedEnvStatus;
  uatMigrationDate: Date;
  isPreProductionMigrated: MigratedEnvStatus;
  preProductionMigrationDate: Date;
  isProductionMigrated: MigratedEnvStatus;
  productionMigrationDate: Date;
  complexity: AssetComplexity;
  frequency: FrequencyOfUse;
  targetAudience: TargetAudience;
};

type DataAssetsFieldsFormProviderProps = {
  onSubmit: SubmitHandler<DataAssetFieldsFormValues>;
  children: React.ReactNode;
};

const DataAssetFieldsFormProvider: React.FC<DataAssetsFieldsFormProviderProps> = ({ onSubmit, children }) => {
  const methods = useForm<DataAssetFieldsFormValues>();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default DataAssetFieldsFormProvider;
