import { Button, Panel } from "@appkit4/react-components";


const LineageModelDetails: React.FC = () => {
  return (
    <div className="pt-4">
      <Panel bordered={false} title="Pull available lineage model" >
        <p>You can pull the available lineage model using the trigger button or upload a CSV file, and test the connectivity of both the pulled model and the uploaded file. During the migration, you will have the ability to access additional lineage modes or add any pending ones.</p>
        <Button className="my-6" kind='primary' icon="icon-upload-outline" type="submit">Pull lineage model</Button>
      </Panel>

    </div>
  )
}

export default LineageModelDetails;