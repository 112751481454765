import { Button, Modal } from "@appkit4/react-components";
import AddUserBodyBusinessUser from "./AddUserBodyBusinessUser";
import { useState } from "react";

interface addUserModalProps {
    visible: boolean;
    closeModal: Function;
    addUsers: Function;
}

const AddUserModalBusinessUser: React.FC<addUserModalProps> = ({ visible, closeModal, addUsers }) => {
    const [businessUserEmails, setbusinessUserEmails] = useState([]);
    const addBusinessUserEmails = (emailsList:any) => {
        setbusinessUserEmails(emailsList);
    }
    return (
        <Modal
            visible={visible}
            title={"Add new Users"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button onClick={addUsers}>Add {businessUserEmails?.length} users</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <AddUserBodyBusinessUser addBusinessUserEmails = {addBusinessUserEmails}/>
        </Modal>);
}

export default AddUserModalBusinessUser;