import { Button, Modal } from "@appkit4/react-components";
import { useFormContext } from "react-hook-form";
import { ControlledDropdown, ControlledInput } from "../../../core/components";
import AddAssetFormProvider from "./AddAssetFormProvider";
import { assetTypeOptions } from "../../../services/constants";
import { AssetType } from "../../../api";

interface AddAssetsModalProps {
    visible: boolean;
    closeModal: Function;
    addAsset: Function;
}

type AddAssetFormErrors = {
    assetName: string;
    assetType: string;
    assetDescription: string;
};

const AddDataAssetModal: React.FC<AddAssetsModalProps> = ({ visible, closeModal, addAsset }) => {
    const addAssetData = (data: {
        assetName: string;
        assetType: AssetType;
        assetDescription: string;
    }) => {
        addAsset(data);
    };
    return (
        <Modal
            visible={visible}
            header={
                <div className="flex-col">
                    <h1 className="font-sans font-medium text-xl mb-4">Add new asset</h1>
                    <div className="font-small text-sm text-[#696969]">To add an asset, please fill out the details form. Once submitted, your request will be forwarded to the responsible manager for approval. The manager will then review and decide whether to approve or reject the request.</div>
                </div>}
            onCancel={closeModal}
            modalStyle={{ width: '36rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <AddAssetFormProvider onSubmit={addAssetData}>
                <ModalContent closeModal={closeModal} />
            </AddAssetFormProvider>
        </Modal>);
}

const ModalContent: React.FC<{ closeModal: Function }> = ({ closeModal }) => {
    const { formState: { errors } } = useFormContext<AddAssetFormErrors>();
    return (
        <div>
            <div className="my-4">
                <ControlledInput name="assetName" required={true} type="text" label="Asset name" error={errors.assetName} />
                {errors.assetName && <p className="!text-red-500 mt-2">{errors.assetName.message}</p>}
            </div>
            <div className="my-4">
                <ControlledDropdown name="assetType" label="Asset type" error={errors.assetType} data={assetTypeOptions} required={true} />
                {errors.assetType && <p className="!text-red-500 mt-2">{errors.assetType.message}</p>}
            </div>
            <div className="my-4">
                <ControlledInput name="assetDescription" required={false} type="text" label="Asset description" error={errors.assetDescription} />
                {errors.assetDescription && <p className="!text-red-500 mt-2">{errors.assetDescription.message}</p>}
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={closeModal}>Cancel</Button>
                <Button kind='primary' icon="icon-plus-outline" type="submit">Add asset</Button>
            </div>
        </div>
    )
}

export default AddDataAssetModal;