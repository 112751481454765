import React from 'react';
import { Badge, Breadcrumb, BreadcrumbItem, Button, Panel } from '@appkit4/react-components';
import backGroundImage from "../../assets/images/genericEmptyState.svg";
import { useSelector } from 'react-redux';
import { selectAllProjectsList } from '../../store/project/projectSelectors';
import { Loading } from '@appkit4/react-components/loading';
import { ProjectDTO } from '../../api';

const LandingManager: React.FC<{ onSubmit: () => void, loading: boolean, goToViewProject: (project: ProjectDTO) => void }> = ({ onSubmit, loading, goToViewProject }) => {
    const listOfProjects = useSelector(selectAllProjectsList);
    const backgroundImageStyle = {
        backgroundImage: `url(${backGroundImage})`,
        backgroundRepeat: 'no-repeat',
        height: '22rem'
    };
    const viewProject = (project: ProjectDTO) => {
        goToViewProject(project);
    }
    return (
        <div>
            {loading ?
                <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
                :
                <>
                    {!(listOfProjects?.length > 0) ?
                        <>
                            <h1 className="font-sans font-medium text-xl mx-8 mt-8 mb-4">Your Projects</h1>
                            <div className="ml-8 mr-8 mt-2 pb-4 bg-white">
                                <div style={backgroundImageStyle} className="bg-center"></div>
                                <div className="flex flex-col text-center items-center">
                                    <h1 className="font-medium text-xl p-2"> You don't have any project assigned</h1>
                                    <div className="text-[#696969]"> Please contact the administrator of the migration project to clarify this topic.</div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="ml-8 mr-8 mt-2 mb-2">
                            <Breadcrumb className="py-4">
                                <BreadcrumbItem className="!text-blue-600">
                                    Your projects
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <h1 className="font-sans font-medium text-xl mb-4">Your projects </h1>
                            <h3 className="font-small text-l py-2 mb-2">Start by selecting a migration project from the list below. Manage and approve data assets, plan migrations, and identify challenges to ensure a secure and successful cloud migration. Your expertise is vital for a seamless transition. </h3>
                            <div className="flex flex-wrap">
                                {listOfProjects?.map((project, index) =>
                                    <div className="w-1/3 mt-4 px-2" key={index}>
                                        <Panel title={project.name} className="min-h-56">
                                            <Badge className="mb-6" value={project.projectStatus} type={project.projectStatus === "ongoing" ? "success" : "warning"} />
                                            <div className="flex justify-end mt-14">
                                                <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => viewProject(project)}>View Project</Button>
                                            </div>
                                        </Panel>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default LandingManager;
