import { Button, Modal } from "@appkit4/react-components";
import { useFormContext } from "react-hook-form";
import RequestFormProvider from "./RequestFormProvider";
import { ControlledTextArea } from "../../../core/components";
import { AssetDTO } from "../../../api";
import { KeycloakService } from "../../../services/KeycloakService";
import { reqToRemoveAssetOwnership, requestForAssetOwnership } from "../../../store/project/projectThunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { setIsEditModeAsset } from "../../../store/project/projectSlice";
import { selectSelectedProjectData } from "../../../store/project/projectSelectors";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ManageAssetModalProps {
    visible: boolean;
    closeModal: Function;
    selectedAsset: any;
}

type RequestOwnerFormErrors = {
    ownerReqComment: string;
};

type RequestRmovalFormErrors = {
    ownerRemovalComment: string;
};

const ManageDataAssetModal: React.FC<ManageAssetModalProps> = ({ visible, closeModal, selectedAsset }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const requestOwnership = (data: any) => {
        dispatch(requestForAssetOwnership({ assetId: selectedAsset?.id, requestDescription: data?.reqOwnerComment }))
        closeModal();
    };
    const reqOwnerRemoval = (data: any) => {
        dispatch(reqToRemoveAssetOwnership({ assetId: selectedAsset?.id, requestDescription: data?.reqOwnerRemovalComment }))
        closeModal();
    };
    return (
        <Modal
            visible={visible}
            header={<h1 className="font-sans font-medium text-xl mb-4">Asset actions</h1>}
            onCancel={closeModal}
            modalStyle={{ width: '36rem' }}
            bodyStyle={{ minHeight: '92px' }}
        >
            <RequestFormProvider onSubmit={requestOwnership}>
                <ReqOwnerModal selectedAsset={selectedAsset} />
            </RequestFormProvider>
            <RequestFormProvider onSubmit={reqOwnerRemoval}>
                <ReqRemovalModal selectedAsset={selectedAsset} />
            </RequestFormProvider>
            <div>
                <h1 className="font-sans font-medium text-xl mb-4">Edit asset</h1>
                <div className="font-small text-sm text-[#696969]">Click here if you would like to edit this asset.</div>
                <div className="my-4">
                    <Button kind="primary" disabled={selectedAsset.owner !== KeycloakService.getEmail()} onClick={() => {
                        dispatch(setIsEditModeAsset(true))
                        navigate(`/project/${selectedProject?.id}/dataAsset/${selectedAsset?.id}`);
                    }
                    }>Edit asset</Button>
                </div>
            </div>
            <div className="flex justify-end gap-2">
                <Button kind='secondary' onClick={closeModal}>Cancel</Button>
            </div>
        </Modal>);
}

const ReqOwnerModal: React.FC<{ selectedAsset: AssetDTO }> = ({ selectedAsset }) => {
    const { formState: { errors } } = useFormContext<RequestOwnerFormErrors>();
    return (
        <div>
            <h1 className="font-sans font-medium text-xl mb-4">Ownership of the asset</h1>
            <div className="font-small text-sm text-[#696969]">If this asset belongs to you, please click 'Request Ownership' and provide a justification in the comment area.</div>
            <div className="my-4">
                <ControlledTextArea name="reqOwnerComment" required={true} label="Comment" error={errors.ownerReqComment} maxLength={420} />
                {errors.ownerReqComment && <p className="!text-red-500 mt-2">{errors.ownerReqComment.message}</p>}
            </div>
            <div className="my-4">
                <Button kind="primary" icon="icon-lockopen-unlock-outline" type="submit" disabled={selectedAsset.owner === KeycloakService.getEmail()}>Request ownership</Button>
            </div>
        </div>
    )
}

const ReqRemovalModal: React.FC<{ selectedAsset: AssetDTO }> = ({ selectedAsset }) => {
    const { formState: { errors } } = useFormContext<RequestRmovalFormErrors>();
    return (
        <div>
            <h1 className="font-sans font-medium text-xl mb-4">Remove asset</h1>
            <div className="font-small text-sm text-[#696969]">If you have a reason for this asset to be removed, please write it in the comments and inform the manager.</div>
            <div className="my-4">
                <ControlledTextArea name="reqOwnerRemovalComment" required={true} label="Comment" error={errors.ownerRemovalComment} maxLength={420} />
                {errors.ownerRemovalComment && <p className="!text-red-500 mt-2">{errors.ownerRemovalComment.message}</p>}
            </div>
            <div className="my-4">
                <Button kind="primary" type="submit" disabled={selectedAsset.owner !== KeycloakService.getEmail()}>Request removal</Button>
            </div>
        </div>
    )
}

export default ManageDataAssetModal;