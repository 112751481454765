import { Button, Panel, Slider } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { Search } from '@appkit4/react-components/search';

const PreviewAssets: React.FC<{ setActiveIndex: Function }> = ({ setActiveIndex }) => {
    const [value, setValue] = useState(50);
    const [showAssets, setShowAssets] = useState(false);
    const handleSearch = (value: string, event: React.SyntheticEvent) => {
        console.log("search called");
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAssets(true)
        }, 5000);
        return () => clearTimeout(timer);
    }, []);
    const nextClicked = () => {
        setActiveIndex(3);
    }
    const cancelProject = () => {
        setActiveIndex(1);
    }
    return (
        <div className='flex-1 bg-[#F3F3F3] mt-4'>
            {!showAssets ?
                <>
                    <h1 className="font-sans font-bold font-medium text-2xl py-4">Connect data assets</h1>
                    <div className="p-8 bg-white">
                        <Panel bordered={false} title="Importing data assets" >
                            <p className="py-4">The data assets are being uploaded. This can take up to 1 minutes.</p>
                            <Slider min={0} max={256} step={10} value={value} sliderId="singleSlider" onValueChange={(i: any) => setValue(i)}></Slider>
                            <p>Importing the data ...</p>
                        </Panel>
                    </div>
                </>
                :
                <>
                    <h1 className="font-sans font-bold font-medium text-2xl py-4">Preview data assets</h1>
                    <div className="px-4 py-4 bg-white flex-row">
                        <div>
                            <Search onSearch={handleSearch} />
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button kind='secondary' onClick={cancelProject}>Back</Button>
                            <Button kind='primary' icon="icon-check-hook-outline" onClick={nextClicked}>Next</Button>
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default PreviewAssets;