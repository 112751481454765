import { Button, Input, Modal, Select } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import { projectRoles } from "../../../services/constants";

interface addUserModalProps {
    visible: boolean;
    closeModal: Function;
    editUser: Function;
    userDetails: {email: string, role: string};
}

const EditUserModal: React.FC<addUserModalProps> = ({ visible, closeModal, editUser, userDetails }) => {
    const [emailValue, setEmailValue] = useState(userDetails?.email);
    const [userRole, setUserRole] = useState(userDetails.role);
    useEffect(() => {
        setEmailValue(userDetails?.email);
        setUserRole(userDetails.role);
    }, [userDetails]);
    return (
        <Modal
            visible={visible}
            title={"Edit users details"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
            footer={
                <>
                    <Button onClick={closeModal} kind="secondary">Cancel</Button>
                    <Button icon="icon-check-hook-outline" onClick={()=>editUser(userRole)}>Save</Button>
                </>
            }
            bodyStyle={{ minHeight: '92px' }}
        >
            <Input className="py-2"
                type={"text"}
                title={"Email"}
                value={emailValue}
                disabled>
            </Input>
            <Select className="py-2" data={projectRoles} dropdownRenderMode="portal" placeholder="Role" value={userRole} onSelect={(e) => setUserRole(e.toString())}></Select>
        </Modal>);
}

export default EditUserModal;