import { Select } from '@appkit4/react-components';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

type ControlledDropdownProps = {
  name: string;
  label?: string;
  error?: FieldError,
  required?: boolean,
  data: Array<{ label: string, value: string }>
};

const ControlledDropdown: React.FC<ControlledDropdownProps> = ({ name, label = "", error, required = false, data }) => {
  const { control, setValue, setError } = useFormContext();
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={required ? { required: `Missing ${label.toLowerCase()}` } : {}}
        render={({ field }) => <Select
          onSelect={(e) => {
            setValue(name, e)
            setError(name, {})
          }}
          className={classNames({
            'error': error
          })}
          required={required}
          data={data}
          placeholder={label}
          dropdownRenderMode="portal"
          {...field} />}
      />
    </div>
  );
};

export default ControlledDropdown;
