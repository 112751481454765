
import React from 'react';
import { ProjectDTO } from '../../api';
import { LandingBusinessUser } from '../../components/Landing';

const LandingContainerBusinessUser: React.FC<{goToViewProject: (project:ProjectDTO) => void}> = ({goToViewProject}) => {
    return (
        <>
            <LandingBusinessUser goToViewProject={goToViewProject}/>
        </>
    )
}

export default LandingContainerBusinessUser;