import { Button, Loading, Panel, Column, Table } from '@appkit4/react-components';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { getProjectsList } from '../../../store/project/projectThunks';
import { selectProjectError, selectProjectLoading, selectSelectedProjectData, selectUserEmailsForProject } from '../../../store/project/projectSelectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getOrgName, removeDuplicates } from '../../../core/util/utility';
import { useCallback, useEffect } from 'react';
import { setUserEmailsForProject } from '../../../store/project/projectSlice';

const ProjectDetailsContainerManager = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const selectedProject = useSelector(selectSelectedProjectData);
    const loading = useSelector(selectProjectLoading);
    const error = useSelector(selectProjectError);
    const getEmailsList = useCallback(() => {
        const emailsArray = [];
        const createEmailsArray: Array<any> = [];
        selectedProject.managerList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "migration-manager" };
            createEmailsArray.push(arrayElement);
        });
        selectedProject.adminList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "admin" };
            createEmailsArray.push(arrayElement);
        });
        selectedProject.businessUserList?.forEach((email: string) => {
            const arrayElement = { email: email, company: getOrgName(email), role: "business-user" };
            createEmailsArray.push(arrayElement);
        });
        emailsArray.push(...createEmailsArray);
        const updatedArrayList = removeDuplicates(emailsArray, 'email');
        return updatedArrayList;
    }, [selectedProject]);

    useEffect(() => {
        dispatch(setUserEmailsForProject(getEmailsList()))
    }, [dispatch, getEmailsList]);
    const tableData = JSON.parse(JSON.stringify(useSelector(selectUserEmailsForProject)));

    if (loading) {
        return (
            <Loading loadingType='circular' indeterminate={true} compact={false}></Loading>
        )
    }

    return (
        <div className="m-8">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Button className="px-0" kind='text' onClick={() => {
                        dispatch(getProjectsList());
                        navigate("/dashboard")
                    }}>All Projects</Button>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    {selectedProject?.name}
                </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="font-sans font-medium text-xl mt-4">{selectedProject?.name}</h1>
            <div className="text-[#696969] mb-4">{selectedProject?.description}</div>
            <div>
                <Panel title={"Team"} className="flex-1">
                    <div className="flex justify-between mb-4">
                        <div>
                            <span className='text-2xl'>{(selectedProject?.adminList?.length || 0) + (selectedProject?.managerList?.length || 0) + (selectedProject?.businessUserList?.length || 0)}</span> members
                        </div>
                        <Button kind='secondary' icon="icon-arrow-right-small-outline" onClick={() => navigate(`/project/${selectedProject?.id}/users`)}>See all</Button>
                    </div>
                    {tableData?.length > 0 &&
                        <Table originalData={tableData} hasTitle className="bg-[#F3F3F3]">
                            <Column field='email' sortKey='email'>Email</Column>
                            <Column field='role' renderCell={(row: any) => <span>{row.role === "admin" ? "Admin" : row.role === "migration-manager" ? "Manager" : "Business User"}</span>} sortKey='role'>Role</Column>
                        </Table>
                    }
                </Panel>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default ProjectDetailsContainerManager;