import { Button, Column, Table, Tag } from "@appkit4/react-components"

const UsersWithEmailTable: React.FC<{ tableData: any, email: string, deleteRow: (row: any) => void, isCompanyRequired?: boolean, editable?: boolean, editRow?: (row: any) => void, isAdminUser?: boolean }> = ({ tableData, email, deleteRow, isCompanyRequired = true, editable = false, editRow, isAdminUser = true }) => {
    return (
        <Table originalData={tableData} hasTitle>
            <Column field='email' sortKey='email'>Email</Column>
            {isCompanyRequired ? <Column field='company' sortKey='company' renderCell={(row) => <Tag type='filled' closable={false}>{row.company.toLowerCase() === "pwc" ? "PwC" : row.company}</Tag>}>Company</Column> : <></>}
            <Column field='role' renderCell={(row) => <span>{row.role === "admin" ? "Admin" : row.role === "migration-manager" ? "Manager" : "Business User"}</span>} sortKey='role'>Role</Column>
            {isAdminUser ?
                <Column field='email' renderCell={(row) => {
                    return (
                        <div className="flex">
                            {email !== row?.email && editable && <Button className="!p-0 h-auto" kind='text' icon="icon-edit-outline" onClick={() => editRow && editRow(row)} />}
                            {email !== row?.email && <Button kind='text' className="!p-0 h-auto" icon="icon-delete-outline" onClick={() => deleteRow(row)} />}
                        </div>
                    )
                }
                }>{editable ? "Actions" : "Action"}</Column>
                :
                <Column field='email' renderCell={(row) => {
                    return (
                        <div className="flex">
                            {email !== row?.email && row?.role !== "admin" && row?.role !== "migration-manager" && <Button kind='text' className="!p-0 h-auto" icon="icon-delete-outline" onClick={() => deleteRow(row)} />}
                        </div>
                    )
                }
                }>{editable ? "Actions" : "Action"}</Column>
            }
        </Table>
    )
}

export default UsersWithEmailTable;